<template>
  <div class="transportation-accounts-vehicle-itinerary">
    <div class="text-xs-right">
      <v-btn fab icon class="mr-2" :disabled="apiInProgress" @click="searchTransportationProfileRoutes()"><v-icon>fal fa-sync-alt</v-icon></v-btn> &nbsp; <v-btn class="primary" :disabled="apiInProgress" @click="createRouteEntry()"><v-icon left>fal fa-plus</v-icon>Create Vehicle Route</v-btn>
    </div>
    <!-- this row is for creating new entries-->
    <v-form v-if="createdFormExpanded" ref="creatingForm" transition="dialog-bottom-transition">
      <v-layout row wrap class="editing-item--background-color">
        <v-flex md2 pa-2>
          <v-switch v-model="editingItem.useCustomRoute" label="Use custom route" />
        </v-flex>
        <v-flex md4 pa-2 class="layout justify-start align-center">
          <v-tooltip top class="mr-2">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">fal fa-info-circle</v-icon>
            </template>
            <div>Route Types:<br />
              <ul>
                <li>Primary: Means always use this route as the initial journey regardless of the day of the week</li>
                <li>Primary (Return): Means always use this route as the return journey regardless of the day of the week (requires Primary added)</li>
                <li>Weekday: Use this route as the initial journey on a specific day of the week</li>
                <li>Weekday (Return): Use this route as the return journey on a specific day of the week (requires Weekday added)</li>
              </ul>
            </div>
          </v-tooltip>
          <v-select
            dense
            box
            :items="routeTypes"
            label="Route Type"
            :rules="routeTypeRules"
            v-model="editingItem.routeType"
            :disabled="apiInProgress"
            :menu-props="{ width: '80px' }"
          />
        </v-flex>
        <v-flex md2 pa-2 v-if="editingItem.routeType === 'weekday' || editingItem.routeType === 'weekdayReturn'">
          <v-select
            :items="weekdays"
            label="Weekdays"
            :rules="weekdayRules"
            v-model="editingItem.weekday"
            :disabled="apiInProgress"
          />
        </v-flex>
        <v-flex :md4="editingItem.routeType === 'weekday' || editingItem.routeType === 'weekdayReturn'" :md6="editingItem.routeType !== 'weekday' && editingItem.routeType !== 'weekdayReturn'" pa-2 class="layout justify-end">
          <div>
            <v-btn :disabled="apiInProgress || itemNotEdited || !isCreatedItemValid" @click="saveRouteEntry()" color="primary">Save</v-btn>
            <v-btn :disabled="apiInProgress || itemNotEdited" @click="cancelItemEdit()" flat small class="text-xs-center">reset</v-btn>
            <v-btn :disabled="apiInProgress" @click="closeRouteEntry()" flat small class="text-xs-center">close</v-btn>
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-if="!editingItem.useCustomRoute" row wrap class="editing-item--background-color">
        <v-flex xs12 md10 pb-1 px-2>
          <v-autocomplete
            dense
            box
            clearable
            open-on-clear
            cache-items
            hide-selected
            return-object
            :rules="stRouteRules"
            :menu-props="{ openOnClick: true }"
            v-model="editingItem.stRoute"
            :items="availableStRoutes"
            :loading="isStRoutesLoading"
            :disabled="!transportationProfile"
            :search-input.sync="stRouteKeywordSearch"
            item-text="label"
            item-value="id"
            label="Selected Route"
            placeholder="Search by route name"
            prepend-icon="fa fa-route"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="editingItem.useCustomRoute" row wrap class="editing-item--background-color">
        <v-flex xs3 pb-1 pl-2 class="layout justify-start align-center">
          <v-btn small :disabled="apiInProgress" @click="loadRouteEditor(editingItem)"><v-icon small left>fas fa-map-marker-edit</v-icon>Set Custom Route</v-btn>
        </v-flex>
        <v-flex xs2 pb-1 pl-2 class="layout justify-start align-center">
          Route label:
        </v-flex>
        <v-flex xs3 pb-1 class="layout justify-start align-center">
          <v-text-field
              class="text-field--flat text-field--small"
              prepend-inner-icon="small fa fa-pencil"
              v-model="editingItem.routeLabelFromEdit"
              hide-details
          ></v-text-field>
        </v-flex>
        <v-flex xs1 pb-1 style="text-align: center" class="layout align-center">
          {{labelSplitter}}
        </v-flex>
        <v-flex xs3 pb-1 pr-2 class="layout justify-start align-center">
          <v-text-field
              class="text-field--flat text-field--small"
              v-model="editingItem.routeLabelToEdit"
              hide-details
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout v-if="editingItem.useCustomRoute" row wrap class="editing-item--background-color">
        <v-flex xs2 pb-2 pl-2>
          Route id list:
        </v-flex>
        <v-flex xs10 pb-2 pr-2>
          <v-text-field
              class="text-field--flat text-field--small"
              :value="editingItem.routeIdListEdit"
              readonly
              hide-details
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="routes"
      :pagination.sync="pagination"
      :total-items="totalRoutes"
      :loading="apiInProgress"
      :rows-per-page-items="[10, 25, 50]"
      @update:pagination="onPagination"
      class="elevation-1"
      must-sort
    >
      <template v-slot:items="props">
        <tr @click="toggleItemEditForm(props)">
          <td>{{ props.item.routeTypeLabel }}</td>
          <td>{{ props.item.weekdayLabel }}</td>
          <td>{{ props.item.stRouteId ? props.item.stRouteLabel : props.item.profileRouteMetadata.routeLabel || 'n/a' }}</td>
          <td class="justify-start layout px-0">
            <v-icon small :disabled="props.expanded" @click.stop="deleteItem(props.item)">
              fas fa-trash-alt
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-form ref="editingForm">
          <v-layout row wrap class="editing-item--background-color">
            <v-flex md2 pa-2 class="layout justify-start align-center">
              <v-switch v-model="editingItem.useCustomRoute" label="Use custom route" />
            </v-flex>
            <v-flex md3 pa-2 class="layout justify-start align-center">
              <v-tooltip top class="mr-2">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fal fa-info-circle</v-icon>
                </template>
                <div>Route Types:<br />
                  <ul>
                    <li>Primary: Means always use this route as the initial journey regardless of the day of the week</li>
                    <li>Primary (Return): Means always use this route as the return journey regardless of the day of the week (requires Primary added)</li>
                    <li>Weekday: Use this route as the initial journey on a specific day of the week</li>
                    <li>Weekday (Return): Use this route as the return journey on a specific day of the week (requires Weekday added)</li>
                  </ul>
                </div>
              </v-tooltip>
              <v-select
                dense
                box
                :items="routeTypes"
                label="Route Type"
                :rules="routeTypeRules"
                v-model="editingItem.routeType"
                :disabled="apiInProgress"
                :menu-props="{ width: '80px' }"
              />
            </v-flex>
            <v-flex md3 pa-2 v-if="editingItem.routeType === 'weekday' || editingItem.routeType === 'weekdayReturn'">
              <v-select
                dense
                :items="weekdays"
                label="Weekdays"
                :rules="weekdayRules"
                v-model="editingItem.weekday"
                :disabled="apiInProgress"
              />
            </v-flex>
            <v-flex :md4="editingItem.routeType === 'weekday' || editingItem.routeType === 'weekdayReturn'" :md7="editingItem.routeType !== 'weekday' && editingItem.routeType !== 'weekdayReturn'" pa-2 class="layout justify-end">
              <div>
                <v-btn :disabled="apiInProgress || itemNotEdited" @click="saveItemEdit(props)" color="primary">Save</v-btn>
                <v-btn :disabled="apiInProgress || itemNotEdited" @click="cancelItemEdit()" flat small class="text-xs-center">reset</v-btn>
                <v-btn :disabled="apiInProgress" @click="closeItemEditForm(props)" flat small class="text-xs-center">close</v-btn>
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="!editingItem.useCustomRoute" row wrap class="editing-item--background-color">
            <v-flex xs12 md10 pb-1 px-2>
              <v-autocomplete
                dense
                box
                clearable
                open-on-clear
                cache-items
                hide-selected
                return-object
                :rules="stRouteRules"
                :menu-props="{ openOnClick: true }"
                v-model="editingItem.stRoute"
                :items="availableStRoutes"
                :loading="isStRoutesLoading"
                :disabled="!transportationProfile"
                :search-input.sync="stRouteKeywordSearch"
                item-text="label"
                item-value="id"
                label="Selected Route"
                placeholder="Search by route name"
                prepend-icon="fa fa-route"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="editingItem.useCustomRoute" row wrap class="editing-item--background-color">
            <v-flex xs3 pb-1 pl-2 class="layout justify-start align-center">
              <v-btn small :disabled="apiInProgress" @click="loadRouteEditor(editingItem)"><v-icon small left>fas fa-map-marker-edit</v-icon>Set Custom Route</v-btn>
            </v-flex>
            <v-flex xs2 pb-1 pl-2 class="layout justify-start align-center">
              Route label:
            </v-flex>
            <v-flex xs3 pb-1 class="layout justify-start align-center">
              <v-text-field
                  class="text-field--flat text-field--small"
                  prepend-inner-icon="small fa fa-pencil"
                  v-model="editingItem.routeLabelFromEdit"
                  hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs1 pb-1 style="text-align: center">
              {{labelSplitter}}
            </v-flex>
            <v-flex xs3 pb-1 pr-2>
              <v-text-field
                  class="text-field--flat text-field--small"
                  v-model="editingItem.routeLabelToEdit"
                  hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout v-if="editingItem.useCustomRoute" row wrap class="editing-item--background-color">
            <v-flex xs2 pb-2 pl-2 class="layout justify-start align-center">
              Route id list:
            </v-flex>
            <v-flex xs10 pb-2 pr-2 class="layout justify-start align-center">
              <v-text-field
                  class="text-field--flat text-field--small"
                  :value="editingItem.routeIdListEdit"
                  readonly
                  hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </template>
    </v-data-table>
    <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        v-model="routeEditorLoaded">
      <v-card>
        <v-toolbar card dark color="primary" dense height="48">
          <img src="@/assets/logo-badge.png" style="height: 25px" alt=""/>
          <v-spacer></v-spacer>
          <v-toolbar-title>Transportation Route Editor/Selector</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="warning" :disabled="!routeIdListFromMap" @click="saveSelectedRouteFromMap">Set Route</v-btn> &nbsp; <v-btn dark flat @click="cancelSelectedRouteFromMap">Cancel</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <st-map
          v-if="routeEditorLoaded && stMapApis"
          container-id="transportationProfileMap"
          :api="stMapApis"
          :mapbox-access-token="mapboxAccessToken"
          :maptiler-key="maptilerKey"
          :route-geometry="editingItem.routeGeometry"
          :height-offset="48"
          sidebar
          topbar
          is-modal
          :markerOptions="markerOptions"
          :route-id-from="editingItem.routeLabelFromEdit"
          :route-id-to="editingItem.routeLabelToEdit"
          :route-id-list="editingItem.routeIdListEdit"
          :on-route-selected="onRouteSelected">
        </st-map>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
