import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import { addAlert, getCachedRegions } from '@/utilities/helpers'
import { genericApiRequests } from '@/utilities/axios-factory'

import {
  getBusStopData,
  getCoarseGeolocation,
  getRoute,
  getRouteByRouteIdList,
  googleGeocodeRequest
} from '@/services/maps-service'

import {
  createTransportationProfileRoute,
  deleteTransportationProfileRoute,
  findTotalTransportationProfileRoutes,
  findTransportationProfileRoutes,
  getBusStopsForProfileRoute,
  getProfileRoute,
  updateTransportationProfileRoute
} from '@/services/transportation-profile-routes-service'
import { findStRoutes, searchOtpRoutes } from '@/services/st-routes-service'
import { geocodeSearch } from '@/services/bus-stops-service'
import { getTransportationProfile } from '@/services/transportation-profiles-service'

export default {
  name: 'transportation-accounts-vehicle-itinerary',
  props: {
    signedInUser: Object,
    vehicleId: [String, Number],
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function,
    routeTypes: Array,
    weekdays: Array
  },
  data () {
    return {
      apiInProgress: false,
      labelSplitter: ' ↔ ',
      editingItem: null,
      clonedEditingItem: null,
      itemNotEdited: true,
      editFormWatchHandle: null,
      routeEditorLoaded: false,
      createdFormExpanded: false,
      routeIdListFromMap: null,
      routeLabelFromMap: null,
      routes: [],
      totalRoutes: 0,
      pagination: null,
      availableStRoutes: [],
      cachedAvailableRoutes: [],
      stRouteKeywordSearch: '',
      isStRoutesLoading: false,
      transportationProfile: null,
      stRouteRules: [v => {
        if (v && this.routes.length) {
          const existingStRoute = this.routes
            .filter(o => {
              let isRouteOpposite = true

              if (this.editingItem.routeType === 'primary') {
                isRouteOpposite = o.isPrimaryReturn
              } else if (this.editingItem.routeType === 'primaryReturn') {
                isRouteOpposite = o.isPrimary
              } else if (this.editingItem.routeType.indexOf('weekday') > -1) {
                if (this.editingItem.routeType === 'weekdayReturn') {
                  isRouteOpposite = !o.isReturn && !o.isPrimary && !o.isPrimaryReturn && o.weekday === this.editingItem.weekday
                } else {
                  isRouteOpposite = o.isReturn && o.isPrimary && o.isPrimaryReturn && o.weekday === this.editingItem.weekday
                }
              }

              return !this.editingItem || (o.id !== this.editingItem.item.id && isRouteOpposite)
            })
            .filter(o => (o.stRouteId === v.id))

          console.warn('this.editingItem', this.editingItem, 'existingStRoute', existingStRoute, 'selection ID', v.id)

          if (existingStRoute?.length) {
            return `${v.label} has already been selected by another profile`
          }
        }

        return true
      }],
      routeTypeRules: [v => {
        if (v && this.routes) {
          const routeType = this.routes.filter(o => !this.editingItem || o.id !== this.editingItem.item.id).filter(o => (o.routeType === v)).length

          if (routeType > 0 && v !== 'weekday') {
            const routeTypeLabel = this.routeTypes.find((rt) => rt.value === v).text
            return `${routeTypeLabel} has already been added`
          }
        } else if (!v) {
          return 'Route type is required'
        }
        return true
      }],
      weekdayRules: [v => {
        if (v && this.routes) {
          const routeType = this.routes.filter(o => !this.editingItem || o.id !== this.editingItem.item.id).filter(o => (o.weekday === v))

          if (routeType?.length) {
            const routeTypeLabel = this.weekdays.find((rt) => rt.value === v).text
            return `${routeTypeLabel} has already been added`
          }
        } else if (!v && v !== 0 && this.editingItem && this.editingItem.routeType.indexOf('weekday') > -1) {
          return 'Weekday is required'
        }

        return true
      }],
      headers: [
        {
          text: 'Route Type',
          align: 'left',
          sortable: true,
          value: 'routeType'
        },
        {
          text: 'Weekday',
          align: 'left',
          sortable: true,
          value: 'weekday'
        },
        {
          text: 'Route Label',
          align: 'left',
          sortable: false
        },
        { text: 'Delete', sortable: false }
      ],
      stMapApis: null,
      markerOptions: { startMarkerClass: 'st-map-marker-start', endMarkerClass: 'st-map-marker-end' },
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY
    }
  },
  watch: {
    stRouteKeywordSearch (val) {
      clearTimeout(this.stRouteKeywordSearchHandle)

      this.stRouteKeywordSearchHandle = setTimeout(() => {
        this.searchStRoutes({ keywords: val })
      }, 800)
    }
  },
  mounted () {
    getTransportationProfile({ id: this.$props.vehicleId })
      .then((transportationProfile) => {
        this.transportationProfile = transportationProfile

        this.searchStRoutes().then((results) => {
          this.cachedAvailableRoutes = [].concat(results)
          this.searchTransportationProfileRoutes()
        })
      })

    this.stMapApis = {
      genericApiRequests,
      getRouteByRouteIdList,
      getRoute,
      getBusStopsForProfileRoute,
      getTransportationProfileRoute: getProfileRoute,
      addAlert,
      getBusStops: getBusStopData,
      getCachedRegions,
      googleGeocodeRequest,
      getCoarseGeolocation,
      searchRoutes: searchOtpRoutes,
      geocodeRequest: (keywords, longlat) => geocodeSearch({ keywords, longlat })
    }
  },
  methods: {
    async searchStRoutes ({ keywords } = {}) {
      try {
        this.isStRoutesLoading = true
        this.availableStRoutes = await findStRoutes({ keywords, order: 'dateUpdated DESC', agencyId: this.transportationProfile.agencyId })
        return this.availableStRoutes
      } catch (err) {
        addAlert({
          message: err,
          type: 'error'
        })
      } finally {
        this.isStRoutesLoading = false
      }
    },
    initEditingItem (item) {
      if (this.editFormWatchHandle) {
        this.editFormWatchHandle()
        this.editFormWatchHandle = null
      }

      this.editingItem = {
        item: item,
        routeGeometry: item.profileRouteMetadata?.routeGeometry || null,
        routeIdListEdit: item.routeIdList,
        routeLabelEdit: item.profileRouteMetadata?.routeLabel || '',
        routeLabelFromEdit: '',
        routeLabelToEdit: '',
        routeType: item.routeType,
        weekday: item.weekday,
        stRoute: this.cachedAvailableRoutes.find((stRoute) => stRoute.id === item.stRouteId),
        useCustomRoute: item.stRouteId ? false : (!!item.profileRouteMetadata?.routeGeometry) // stRouteId takes precedence over routeGeometry, if no RouteGeometry, prefer to select stRouteID
      }

      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.clonedEditingItem = _cloneDeep(this.editingItem)
      this.itemNotEdited = true

      if (this.editFormWatchHandle) {
        this.editFormWatchHandle()
      }

      this.editFormWatchHandle = this.$watch('editingItem', (newVal) => {
        this.itemNotEdited = _isEqual(newVal, this.clonedEditingItem)
      }, { deep: true })
    },
    onRouteSelected ({ routeIdList, routeGeometry, from, to } = {}) {
      this.routeIdListFromMap = routeIdList || null

      if (routeGeometry) {
        this.routeGeometryFromMap = routeGeometry
      }

      this.routeLabelFromMap = [from, to].filter(Boolean).join(this.labelSplitter)
    },
    saveSelectedRouteFromMap () {
      this.editingItem.routeIdListEdit = this.routeIdListFromMap
      this.editingItem.routeGeometry = this.routeGeometryFromMap
      this.editingItem.routeLabelEdit = this.routeLabelFromMap
      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.routeEditorLoaded = false
    },
    cancelSelectedRouteFromMap () {
      this.routeIdListFromMap = null
      this.routeLabelFromMap = null
      this.routeGeometryFromMap = null
      this.routeEditorLoaded = false
    },
    createRouteEntry () {
      this.initEditingItem({ routeIdList: '', routeLabel: '' })
      this.createdFormExpanded = true
    },
    closeRouteEntry () {
      if (this.cancelItemEdit()) {
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }
        this.createdFormExpanded = false
        this.itemNotEdited = true
      }
    },
    saveRouteEntry () {
      this.apiInProgress = true

      this.saveItemEdit().then(() => {
        this.closeRouteEntry()
      }).catch(_ => {}).finally(() => {
        this.apiInProgress = false
      })
    },
    async deleteItem (route) {
      const routeLabel = route.stRoute?.label || route.profileRouteMetadata?.routeLabel || ''

      if (confirm(`Confirm deleting "${routeLabel}"`)) {
        try {
          this.apiInProgress = true
          await deleteTransportationProfileRoute({ id: route.id })

          addAlert({
            message: `Successfully deleted "${routeLabel}"`,
            type: 'success',
            transient: true
          })

          return this.searchTransportationProfileRoutes()
        } catch (err) {
          addAlert({
            message: err,
            type: 'error'
          })
        } finally {
          this.apiInProgress = false
        }
      }
    },
    toggleItemEditForm (props) {
      if (!this.createdFormExpanded && this.itemNotEdited) {
        props.expanded = !props.expanded

        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }

        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }

        if (props.expanded) {
          this.createFormExpandedWatcher = this.$watch('createdFormExpanded', (newVal) => {
            if (newVal) {
              props.expanded = false
              this.createFormExpandedWatcher()
              this.createFormExpandedWatcher = null
            }
          })

          this.initEditingItem(props.item)
        } else {
          this.itemNotEdited = true
        }
      }
    },
    closeItemEditForm (props) {
      if (this.cancelItemEdit()) {
        props.expanded = false

        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
          this.clonedEditingItem = null
        }

        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }

        this.itemNotEdited = true
      }
    },
    isEditingItemValid () {
      return this.$refs && this.$refs.editingForm && this.$refs.editingForm.validate()
    },
    isCreatedItemValid () {
      return this.$refs && this.$refs.creatingForm && this.$refs.creatingForm.validate()
    },
    async saveItemEdit (props = undefined) {
      const weekdays = this.routes.filter(o => (o.routeType === 'weekday'))
      const weekdayReturns = this.routes.filter(o => (o.routeType === 'weekdayReturn'))

      if (weekdayReturns.length > weekdays.length) {
        return alert('Please add weekdays to match the number of weekday returns')
      }

      if (weekdays.length > 7) {
        return alert('Only seven weekday entries can be added')
      }

      const routeTypeRules = this.routeTypeRules[0](this.editingItem.routeType)

      if (routeTypeRules !== true) {
        return alert(routeTypeRules)
      }

      const weekdayRules = this.weekdayRules[0](this.editingItem.weekday)

      if (weekdayRules !== true) {
        return alert(weekdayRules)
      }

      if ((this.editingItem.item.id && this.isEditingItemValid()) || (!this.editingItem.item.id && this.isCreatedItemValid())) {
        if (this.editingItem.useCustomRoute) {
          if (this.editingItem.routeLabelFromEdit && this.editingItem.routeLabelToEdit) {
            this.editingItem.item.profileRouteMetadata = this.editingItem.item.profileRouteMetadata || {}
            this.editingItem.item.profileRouteMetadata.routeLabel = this.editingItem.routeLabelFromEdit + this.labelSplitter + this.editingItem.routeLabelToEdit
          } else if (!this.editingItem.routeLabelFromEdit || !this.editingItem.routeLabelToEdit) {
            return alert('The route label requires a "to" and "from"')
          }

          this.editingItem.stRoute = null
        } else {
          if (!this.editingItem.stRoute) {
            return alert('Please select a route')
          }
        }

        try {
          const updatedItem = {
            id: this.editingItem.item.id,
            profileRouteMetadata: this.editingItem.item.profileRouteMetadata,
            isPrimary: this.editingItem.routeType === 'primary',
            isPrimaryReturn: this.editingItem.routeType === 'primaryReturn',
            isReturn: this.editingItem.routeType === 'weekdayReturn',
            weekday: this.editingItem.routeType === 'weekday' || this.editingItem.routeType === 'weekdayReturn' ? this.editingItem.weekday : null,
            routeIdList: this.editingItem.routeIdListEdit,
            routeGeometry: this.editingItem.routeGeometry,
            stRouteId: this.editingItem.stRoute?.id || ''
          }

          if (this.editingItem.item.id) {
            await updateTransportationProfileRoute(updatedItem)
          } else {
            await createTransportationProfileRoute({
              transportationProfileId: this.$props.vehicleId,
              data: updatedItem
            })
          }

          if (!this.editingItem.item.id) {
            this.searchKeywords = ''
          }

          this.clonedEditingItem = _cloneDeep(this.editingItem)
          this.itemNotEdited = true

          if (props) {
            this.closeItemEditForm(props)
          }

          const routeLabel = this.editingItem.stRoute?.label || this.editingItem.item.profileRouteMetadata?.routeLabel || ''

          addAlert({
            message: `Successfully ${this.editingItem.item.id ? 'updated' : 'created'} "${routeLabel}"`,
            type: 'success',
            transient: true
          })

          await this.searchStRoutes()
          await this.searchTransportationProfileRoutes()
        } catch (err) {
          addAlert({
            message: err,
            type: 'error'
          })
        }
      }
    },
    cancelItemEdit () {
      if (!this.itemNotEdited) {
        if (confirm('Are you sure you want to undo your edits?')) {
          this.editingItem = _cloneDeep(this.clonedEditingItem)
          this.itemNotEdited = true
          return true
        }

        return false
      }

      return true
    },
    loadRouteEditor () {
      this.routeEditorLoaded = true
    },
    onPagination () {
      if (this.countryCurrency) {
        this.searchStRoutes().then(() => {
          this.searchTransportationProfileRoutes()
        })
      }
    },
    async searchTransportationProfileRoutes () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage

      let filter = {
        limit: rowsPerPage,
        offset,
        where: { transportationProfileId: this.$props.vehicleId || '-1' }
      }

      if (!sortBy) {
        filter.order = ['isPrimary DESC']
      } else {
        filter.order = []
        let sortField

        switch (sortBy) {
          case 'primary':
            sortField = 'isPrimary'
            break
          case 'primaryReturn':
            sortField = 'isPrimaryReturn'
            break
          case 'week':
          case 'weekReturn':
            sortField = 'weekday'
            break
          default:
            sortField = 'isPrimary'
        }

        if (sortBy === 'weekdayReturn') {
          filter.order.push(`isReturn ${descending ? 'DESC' : 'ASC'}`)
        }

        filter.order.push(`${sortField} ${descending ? 'DESC' : 'ASC'}`)
      }

      this.apiInProgress = true

      const [routes, totalRoutes] = await Promise.all([
        findTransportationProfileRoutes(filter),
        findTotalTransportationProfileRoutes(filter)
      ])

      this.routes = routes.map(o => {
        o.weekdayLabel = 'n/a'

        if (o.isPrimary) {
          o.routeType = 'primary'
        } else if (o.isPrimaryReturn) {
          o.routeType = 'primaryReturn'
        } else if (o.weekday || o.weekday === 0) {
          o.routeType = 'weekday'

          if (o.isReturn) {
            o.routeType = 'weekdayReturn'
          }

          o.weekdayLabel = this.weekdays.find((rt) => o.weekday === rt.value)
          o.weekdayLabel = o.weekdayLabel ? o.weekdayLabel.text : ''
        }

        o.stRouteLabel = o.stRouteId ? this.availableStRoutes.find((stRoute) => stRoute.id === o.stRouteId)?.label || 'n/a' : ''
        o.routeTypeLabel = this.routeTypes.find((rt) => o.routeType === rt.value)
        o.routeTypeLabel = o.routeTypeLabel ? o.routeTypeLabel.text : ''
        return o
      })

      this.totalRoutes = totalRoutes.count
      this.apiInProgress = false
    }
  },
  beforeDestroy () {
    if (typeof this.editFormWatchHandle === 'function') {
      this.editFormWatchHandle()
    }

    if (typeof this.createFormExpandedWatcher === 'function') {
      this.createFormExpandedWatcher()
    }
  }
}
